<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
    export default {
        page: {
            title: "GDPR",
            items: [
            {
                text: "XIVDATA",
                href: "/"
            },
            {
                text: "About",
                href: "/"
            },
            {
                text: "GDPR",
                active: true
            }
        ],
        meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
    }
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <p v-html="$t('about.gdpr')"></p>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>